<template>
  <!-- register START -->
  <div class="register">

    <!-- register:progress START -->
    <div class="progress">
      <div
        class="progress__bar"
        :style="{ width: totalWidth + '%' }"
      ></div>
    </div>
    <!-- register:progress END -->

    <!-- form:register START -->
    <el-form
      status-icon
      :rules="rules"
      ref="registerForm"
      :model="registerForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="executeRecaptcha"
    >

      <!-- form:register:personal-data START -->
      <div class="register-step">
        <personal-data
          v-if="progressActiveIndex === 0"
          :registerForm="registerForm"
          :validateStep="validateStep"
          :executeRecaptcha="executeRecaptcha"
          :progressActiveIndex="progressActiveIndex"
          :globalErrorMessage="globalErrorMessage"
          :pickerOptions="pickerOptions"
        />
      </div>
      <!-- form:register:personal-data END -->

      <!-- form:register:address START -->
      <div class="register-step">
        <address-data
          v-if="progressActiveIndex === 1"
          :registerForm="registerForm"
          :validateStep="validateStep"
          :executeRecaptcha="executeRecaptcha"
          :companySwitch="companySwitch"
          :globalErrorMessage="globalErrorMessage"
          :backStep="backStep"
        />
      </div>
      <!-- form:register:address END -->

      <!-- form:register:password START -->
      <div class="register-step">
        <password-data
          :type="type"
          v-if="progressActiveIndex === 2"
          :registerForm="registerForm"
          :validateStep="validateStep"
          :executeRecaptcha="executeRecaptcha"
          :showHidePassword="showHidePassword"
          :globalErrorMessage="globalErrorMessage"
          :backStep="backStep"
        />
      </div>
      <!-- form:register:password END -->

      <!-- form:register:pin START -->
      <div class="register-final-step">
        <pin-data
          v-if="progressActiveIndex === 3"
          :registerForm="registerForm"
          :validateStep="validateStep"
          :executeRecaptcha="executeRecaptcha"
          :globalErrorMessage="globalErrorMessage"
          :submitForm="submitForm"
          :resetForm="resetForm"
          :backStep="backStep"
        />
      </div>
      <!-- form:register:pin END -->

      <!-- form:google-recaptcha:submit START -->
      <recaptcha
        ref="recaptcha"
        v-on:verify="verifyForm"
      ></recaptcha>
      <!-- form:google-recaptcha:submit END -->

    </el-form>
    <!-- form:register END -->

    <!-- form:register:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:register:loading END -->

  </div>
  <!-- register END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';
import validateMixin from '@/mixins/validateMixin';

export default {
  name: 'register',
  mixins: [titleMixin, validateMixin],
  components: {
    PersonalData: () => import('../components/register/PersonalData.vue'),
    AddressData: () => import('../components/register/AddressData.vue'),
    PasswordData: () => import('../components/register/PasswordData.vue'),
    PinData: () => import('../components/register/PinData.vue'),
    LoadingDefault: () => import('../components/shared/loading/Default.vue'),
    Recaptcha: () => import('../components/shared/Recaptcha.vue'),
  },
  data() {
    const t = this;
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s\- /()]*)$/mg; // /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s]*)$/mg; // eslint-disable-line
      if (!value) {
        return callback(new Error('Phone number cannot be empty'));
      }
      if (phoneReg.test(value)) {
        callback();
      } else {
        callback(new Error('The phone number format is incorrect'));
      }
      return value;
    };
    return {
      googleRecaptchaToken: '',
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('login.registerheadline'),
      /**
       * Progress
       */
      totalWidth: 0,
      progressActiveIndex: 0,

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.register.headline'),
      loadingExcerpt: this.$t('loading.register.excerpt'),

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      companySwitch: false,
      type: 'password',
      genderValue: '',

      /**
       * Form (Models) + Pass data to mixin
       */
      messagePassword: this.$t('login.messagepassword'),
      confirmationPassword: this.$t('login.confirmationpassword'),
      registerForm: {
        /**
         * Personal Data
         */
        title: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        phone: '',
        email: '',

        /**
         * Address Data
         */
        road: '',
        houseNumber: '',
        staircaseNumber: '',
        floor: '',
        door: '',
        postcode: '',
        place: '',
        country: '',
        company: '',
        companyUID: '',

        /**
         * Password Data
         */
        password: '',
        confirmPassword: '',

        /**
         * PIN Data
         */
        pin: '',
        agbs: [],
      },
      country: null,

      /**
       * Form (Rules - Validation)
       */
      rules: {
        /**
         * Personal Data
         */
        gender: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        firstName: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        lastName: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        birthDate: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        phone: [
          {
            required: true,
            validator: checkPhone,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            type: 'email',
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],

        /**
         * Address Data
         */
        road: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        houseNumber: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            min: 1,
            max: 10,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        postcode: [
          {
            required: true,
            min: 4,
            max: 5,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        place: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        company: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        companyUID: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],

        /**
         * Password Data
         */
        password: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validatePassword,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validateConfirmPassword,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],

        /**
         * PIN Data
         */
        pin: [
          {
            required: true,
            pattern: /^[^-\s][a-zA-Z0-9_\s-]+$/,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            pattern: /^\d{4}$/,
            message: 'Bitte geben Sie 4-stelliger Code der Zahlen 0 bis 9 ein.',
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        agbs: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'change',
            ],
          },
        ],
      },
      pickerOptions: {
        disabledDate(date) {
          const age = t.$date(new Date()).diff(t.$date(date), 'year');
          return age < 12;
        },
      },
    };
  },
  async created() {
    this.country = await this.FETCH_COUNTRIES();
  },
  methods: {
    async FETCH_COUNTRIES() {
      await this.$store.dispatch('setCountries');
      return this.$store.getters.countries;
    },

    validateStep(formName, i) {
      if (formName === 'registerForm') {
        this.$refs[formName].validate((valid) => {
          if (valid && i === 1) {
            this.globalErrorMessage = false;
            this.calculateStep(i);
            return true;
          }
          if (valid && i === 0) {
            this.globalErrorMessage = false;
            return true;
          }
          this.globalErrorMessage = true;
          this.error(`${this.$t('login.globalerrormessage')}`);
          console.log('error register submit!!');
          return false;
        });
      }
    },

    submitForm(formName) {
      console.log('submit');
      const formData = new FormData();
      if (formName === 'registerForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Personal Data
             */
            formData.append('genderID', this.registerForm.gender);
            formData.append('academicTitle', this.registerForm.title);
            formData.append('firstName', this.registerForm.firstName);
            formData.append('lastName', this.registerForm.lastName);
            formData.append('dateOfBirth', this.$date(this.registerForm.birthDate).format('YYYY-MM-DD'));
            formData.append('phoneNumber', this.registerForm.phone);
            formData.append('email', this.registerForm.email);
            /**
             * Address Data
             */
            formData.append('street', this.registerForm.road);
            formData.append('houseNumber', this.registerForm.houseNumber);
            formData.append('staircaseNumber', this.registerForm.staircaseNumber);
            formData.append('floorNumber', this.registerForm.floor);
            formData.append('doorNumber', this.registerForm.door);
            formData.append('zip', this.registerForm.postcode);
            formData.append('city', this.registerForm.place);
            this.country.forEach((c) => {
              if (c.code === this.registerForm.country || c.value === this.registerForm.country) {
                this.registerForm.country = c.code;
                formData.append('countryCode', this.registerForm.country);
              }
            });
            formData.append('companyName', this.registerForm.company);
            formData.append('companyVatNumber', this.registerForm.companyUID);

            /**
             * Password Data
             */
            formData.append('password', this.registerForm.password);
            formData.append('repassword', this.registerForm.confirmPassword);

            /**
             * PIN Data
             */
            formData.append('pin', this.registerForm.pin);
            this.registerForm.agbs = true;
            formData.append('agbs', this.registerForm.agbs);

            /**
             * Recaptcha
             */
            formData.append('g-recaptcha-response', this.googleRecaptchaToken);

            /* Display the key/value pairs
            [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Register
            this.$http.post(`${process.env.VUE_APP_BASE_URL}register`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  const user = response.data;
                  const auth = user.token;
                  const eToken = user.user.encryptedToken;

                  // mutating to store for client rendering &
                  // saving token in cookie for server rendering
                  this.$store.commit('SET_AUTH', auth);
                  localStorage.setItem('auth', auth);

                  // mutating to store for client rendering &
                  // saving user data in cookie for server rendering
                  this.$store.commit('SET_USER', user);
                  localStorage.setItem('user', JSON.stringify(user));
                  localStorage.setItem('timeout', user.timeout);

                  // mutating to store for client rendering &
                  // saving token in cookie for server rendering
                  this.$store.commit('SET_ETOKEN', eToken);
                  localStorage.setItem('eToken', eToken);

                  // saving info & don't show Welcome Screen again, if once registered/logged in
                  localStorage.setItem('welcomeScreen', false);

                  this.$router.push('/home');
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  // Close loading
                  this.loadingVisible = false;
                  this.errorStatus = error.response.data;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    calculateStep(i) {
      const step = document.querySelectorAll('.register-step');
      const stepsLength = step.length;

      if (i) {
        this.progressActiveIndex += i;
      }

      this.totalWidth = Math.round((this.progressActiveIndex / stepsLength) * 100);
    },

    backStep(i) {
      const step = document.querySelectorAll('.register-step');
      const stepsLength = step.length;

      if (i) {
        this.progressActiveIndex += i;
      }

      this.totalWidth = Math.round((this.progressActiveIndex / stepsLength) * 100);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },

    verifyForm(googleRecaptchaResponse) {
      if (googleRecaptchaResponse) {
        this.googleRecaptchaToken = googleRecaptchaResponse;
        this.submitForm('registerForm');
      }
    },

    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>
